import React from 'react';
import classNames from 'classnames';

const FooterSocial = ({
  className,
  ...props
}) => {

  const classes = classNames(
    'footer-social',
    className
  );

  return (
    <div
      {...props}
      className={classes}
    >
      <ul className="list-reset">
        <li>
          Registry Code <b>14252679</b>
        </li>
        <li>
          VAT Number <b>EE101969493</b>
        </li>
      </ul>
    </div>
  );
}

export default FooterSocial;